import Highcharts from 'highcharts/highcharts.src'
import HighchartsBoost from 'highcharts/modules/boost'

import merge from 'lodash.merge'

HighchartsBoost(Highcharts)

// ehm, this fixes error showing mechanism of Highcharts,
// it actually depends on Highcharts global variable
window.Highcharts = Highcharts

async function replaceScript (elementId) {
  /** @type {HTMLScriptElement} script **/
  const script = document.getElementById(elementId)
  if (!script) return []

  function placeChart (data) {
    const chartEl = document.createElement('div')
    chartEl.id = script.id
    chartEl.className = script.className
    chartEl.classList.add('chart-loaded')
    script.parentElement.insertBefore(chartEl, script)
    script.remove()

    return [chartEl, data]
  }

  if (script.textContent.length > 0) {
    const data = JSON.parse(script.textContent)
    return placeChart(data)
  } else if (script.src.length > 0) {
    const response = await fetch(script.src, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    return placeChart(data)
  }
}

/**
 * @param {string, null} number
 * @return {numeric, null}
 */
function toNumber (number) {
  return parseFloat(number, 10)
}

/**
 * @param {string} name
 * @param {Array.<Object>} data
 * @param {function} fun
 * @param {Highcharts.Series} extra
 * @return {Highcharts.Series} series
 */
function createDataSeries (name, data, fun, extra = null) {
  return merge({
    name,
    type: 'line',
    data: data.map((record, index) => [Date.parse(record.measured_at), toNumber(fun(record)), index]),
    lineWidth: 1,
    marker: {
      symbol: 'circle',
      enabled: undefined
    },
    keys: ['x', 'y', 'custom.id'],
    tooltip: {
      valueDecimals: 2,
      headerFormat: '<span style="font-size: 10px">{point.key} (Index {point.point.custom.id})</span><br/>',
      pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>\n'
    },
    states: {
      hover: {
        lineWidth: 0,
        lineWidthPlus: 0
      }
    }
  }, extra || {})
}

function syncExtremes (e) {
  const thisChart = this.chart

  if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
    Highcharts.each(Highcharts.charts, function (chart) {
      if (chart !== thisChart) {
        if (chart.xAxis[0].setExtremes) { // It is null while updating
          chart.xAxis[0].setExtremes(
            e.min,
            e.max,
            undefined,
            false,
            { trigger: 'syncExtremes' }
          )
        }
      }
    })
  }
}

Highcharts.Pointer.prototype.reset = function () {
  return undefined
}

/**
 * Highlight a point by showing tooltip, setting hover state and draw crosshair
 */
Highcharts.Point.prototype.highlight = function (event) {
  event = this.series.chart.pointer.normalize(event)
  this.onMouseOver() // Show the hover marker
  this.series.chart.tooltip.refresh(this) // Show the tooltip
  this.series.chart.xAxis[0].drawCrosshair(event, this) // Show the crosshair
}

async function initializeHighcharts () {
  const [el, data] = await replaceScript('highcharts')
  if (!el) return

  const envChart = document.createElement('div')
  const corrosionChart = document.createElement('div')

  el.appendChild(corrosionChart)
  el.appendChild(envChart)

  for (const eventType of ['mousemove', 'touchmove', 'touchstart']) {
    continue
    el.addEventListener(
      eventType,
      function (e) {
        let chart,

          i,
          event

        for (i = 0; i < Highcharts.charts.length; i = i + 1) {
          chart = Highcharts.charts[i]
          // Find coordinates within the chart
          event = chart.pointer.normalize(e)
          // Get the hovered point
          for (const series of chart.series) {
            const point = series.searchPoint(event, true)?.highlight(e)
            if (point) {
              // point.highlight(e)
            }
          }
        }
      }
    )
  }

  const envSeries = []
  const corrosionSeries = []
  const scatterOptions = { type: 'line', marker: { radius: 2 }, tooltip: { valueSuffix: ' µm' } }
  const lineOptions = { type: 'line', marker: { enabled: false }, tooltip: { valueSuffix: ' µm' } }

  const notNegative = (num) =>
    typeof (num) === 'number' ? Math.max(0, num) : null

  envSeries.push(createDataSeries('Temperature', data, (record) => record.temperature, merge({}, scatterOptions, {
    yAxis: 0,
    tooltip: { valueSuffix: ' °C' }
  })))
  envSeries.push(createDataSeries('Humidity', data, (record) => record.humidity, merge({}, scatterOptions, {
    yAxis: 1,
    tooltip: { valueSuffix: ' %' }
  })))

  corrosionSeries.push(createDataSeries('CR1', data, (record) =>
    notNegative(record.corrosion_rate?.cr1), merge({}, lineOptions, {
    type: 'areaspline',
    stacking: 'normal',
    yAxis: 1,
    tooltip: { valueSuffix: '  µm/year' },
    marker: { symbol: 'circle' }
  })))
  corrosionSeries.push(createDataSeries('CR2', data, (record) =>
    notNegative(record.corrosion_rate?.cr2), merge({}, lineOptions, {
    type: 'areaspline',
    stacking: 'normal',
    yAxis: 1,
    tooltip: { valueSuffix: '  µm/year' },
    marker: { symbol: 'square' }
  })))
  corrosionSeries.push(createDataSeries('CR3', data, (record) =>
    notNegative(record.corrosion_rate?.cr3), merge({}, lineOptions, {
    type: 'areaspline',
    stacking: 'normal',
    yAxis: 1,
    tooltip: { valueSuffix: '  µm/year' },
    marker: { symbol: 'triangle' }
  })))

  corrosionSeries.push(createDataSeries('CD1', data, (record) =>
    notNegative(record.corrosion_depth?.ema_smooth_cd1), merge({}, lineOptions, {
    visible: false,
    marker: { symbol: 'circle' }
  })))
  corrosionSeries.push(createDataSeries('CD1 (raw)', data, (record) =>
    notNegative(record.corrosion_depth?.cd1), merge({}, scatterOptions, {
    visible: false,
    marker: { symbol: 'circle' },
    linkedTo: ':previous',
    opacity: 0.5,
    zIndex: 0
  })))

  corrosionSeries.push(createDataSeries('CD2', data, (record) =>
    notNegative(record.corrosion_depth?.ema_smooth_cd2), merge({}, lineOptions, {
    visible: false,
    marker: { symbol: 'square' }
  })))
  corrosionSeries.push(createDataSeries('CD2 (raw)', data, (record) =>
    notNegative(record.corrosion_depth?.cd2), merge({}, scatterOptions, {
    visible: false,
    marker: { symbol: 'square' },
    linkedTo: ':previous',
    opacity: 0.5,
    zIndex: 0
  })))

  corrosionSeries.push(createDataSeries('CD3', data, (record) =>
    notNegative(record.corrosion_depth?.ema_smooth_cd3), merge({}, lineOptions, {
    visible: false,
    marker: { symbol: 'triangle' }
  })))
  corrosionSeries.push(createDataSeries('CD3 (raw)', data, (record) =>
    notNegative(record.corrosion_depth?.cd3), merge({}, scatterOptions, {
    visible: false,
    marker: { symbol: 'triangle' },
    linkedTo: ':previous',
    opacity: 0.5,
    zIndex: 0
  })))

  const invalidBands = []
  let band = null
  for (const record of data) {
    if (!record.within_bounds) {
      if (!band) {
        band = {
          from: Date.parse(record.measured_at),
          color: 'rgba(255,0,0,0.1)',
          borderColor: 'rgba(255,0,0,0.5)'
        }
      }

      band.to = Date.parse(record.measured_at)
    }

    if (band && record.within_bounds) {
      invalidBands.push(band)
      band = null
    }
  }

  Highcharts.chart({
    chart: {
      renderTo: corrosionChart,
      zoomType: 'x',
      resetZoomButton: {
        relativeTo: 'spacingBox',
        position: {
          y: 0,
          x: -60
        }
      }
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      followPointer: true
    },
    title: {
      text: ' '
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      minRange: 3600000 * 3,
      events: {
        setExtremes: syncExtremes
      },
      plotBands: invalidBands
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        shadow: false
      },
      scatter: {
        lineWidth: 0
      },
      line: {
        lineWidth: 0.5
      }
    },
    yAxis: [
      {
        title: {
          text: 'Corrosion depth (µm)',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        },
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[0]
          }
        }
      },
      {
        title: {
          text: 'Corrosion rate (µm/year)',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        labels: {
          format: '{value}',
          style: {
            color: Highcharts.getOptions().colors[1]
          }
        },
        opposite: true
      }
    ],
    series: corrosionSeries
  })

  Highcharts.chart({
    chart: {
      renderTo: envChart,
      zoomType: 'x',
      resetZoomButton: {
        relativeTo: 'spacingBox',
        position: {
          y: 0,
          x: -60
        }
      }
    },
    tooltip: {
      shared: true,
      split: true,
      crosshairs: true,
      followPointer: true
    },
    title: {
      text: ' '
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      minRange: 3600000 * 3
    },
    yAxis: [
      {
        labels: {
          format: '{value} °C',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: 'Temperature',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        }
      },
      {
        labels: {
          format: '{value} %',
          style: {
            color: Highcharts.getOptions().colors[3]
          }
        },
        title: {
          text: 'Humidity',
          style: {
            color: Highcharts.getOptions().colors[3]
          }
        },
        min: 0,
        max: 100,
        opposite: true
      }
    ],
    series: envSeries
  })
}

document.addEventListener('turbo:load', initializeHighcharts)
